/**
 * Talala API Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GlassesAttributesV2 } from './glassesAttributesV2';

export interface GlassesAttributes {
  id: string;
  user_id?: string;
  image: string;
  rarity: string;
  in_inner_wallet: boolean;
  in_marketplace: boolean;
  current_offer_id: number;
  price: string;
  v2: GlassesAttributesV2;
}
