/**
 * Talala API Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type PaymentProvider = 'apple' | 'internal' | 'external';

export const PaymentProvider = {
  Apple: 'apple' as PaymentProvider,
  Internal: 'internal' as PaymentProvider,
  External: 'external' as PaymentProvider,
};
