/**
 * Talala API Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface UserStateData {
  user_id?: string;
  attention?: number;
  attention_max?: number;
  next_attention_refill?: number;
  token_daily_reward?: number;
  token_daily_reward_cap?: number;
  blocked?: boolean;
  wallet_address?: string;
  equipped_glasses_id?: string | null;
}
