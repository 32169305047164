/**
 * Talala API Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GetMarketItemsData } from './getMarketItemsData';
import { GetItemsData } from './getItemsData';
import { MarketItemsInner } from './marketItemsInner';

/**
 * @type ItemsData
 * @export
 */
export type ItemsData = GetItemsData | GetMarketItemsData;
