/**
 * Talala API Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type RarityStrApi =
  | 'common'
  | 'starting'
  | 'elementary'
  | 'smart'
  | 'lite'
  | 'simple'
  | 'rare'
  | 'unique'
  | 'epic'
  | 'legendary'
  | 'customized';

export const RarityStrApi = {
  Common: 'common' as RarityStrApi,
  Starting: 'starting' as RarityStrApi,
  Elementary: 'elementary' as RarityStrApi,
  Smart: 'smart' as RarityStrApi,
  Lite: 'lite' as RarityStrApi,
  Simple: 'simple' as RarityStrApi,
  Rare: 'rare' as RarityStrApi,
  Unique: 'unique' as RarityStrApi,
  Epic: 'epic' as RarityStrApi,
  Legendary: 'legendary' as RarityStrApi,
  Customized: 'customized' as RarityStrApi,
};
