/**
 * Talala API Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CaseMarket } from './caseMarket';

export interface GetCaseMarketData {
  type: GetCaseMarketData.TypeEnum;
  object: CaseMarket;
}
export namespace GetCaseMarketData {
  export type TypeEnum = 'CASE';
  export const TypeEnum = {
    Case: 'CASE' as TypeEnum,
  };
}
