/**
 * Talala API Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MarketplaceOfferObject } from './marketplaceOfferObject';
import { GetGlassesMarketData } from './getGlassesMarketData';
import { GetCaseMarketData } from './getCaseMarketData';
import { MarketOfferV2 } from './marketOfferV2';

/**
 * @type MarketItemsInner
 * @export
 */
export type MarketItemsInner =
  | GetCaseMarketData
  | GetGlassesMarketData
  | MarketplaceOfferObject;
