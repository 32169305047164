/**
 * Talala API Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CaseLimitsObjectV21 } from './caseLimitsObjectV21';

export interface CaseLimitsObject {
  rarities: Array<string>;
  sorts: Array<string>;
  v2: CaseLimitsObjectV21;
}
