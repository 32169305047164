/**
 * Talala API Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppleProviderObjectData } from './appleProviderObjectData';

export interface AppleProviderData {
  type: AppleProviderData.TypeEnum;
  object: AppleProviderObjectData;
}
export namespace AppleProviderData {
  export type TypeEnum = 'apple';
  export const TypeEnum = {
    Apple: 'apple' as TypeEnum,
  };
}
