/**
 * Talala API Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CaseAttributes } from './caseAttributes';

export interface GetCaseData {
  type: GetCaseData.TypeEnum;
  object: CaseAttributes;
}
export namespace GetCaseData {
  export type TypeEnum = 'CASE';
  export const TypeEnum = {
    Case: 'CASE' as TypeEnum,
  };
}
