/**
 * Talala API Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type Platform = 'iOS' | 'Android' | 'Web';

export const Platform = {
  IOs: 'iOS' as Platform,
  Android: 'Android' as Platform,
  Web: 'Web' as Platform,
};
