/**
 * Talala API Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BaseProviderData } from './baseProviderData';
import { PurchaseAssetOfferModel } from './purchaseAssetOfferModel';
import { PurchaseAssetOrderModelV2 } from './purchaseAssetOrderModelV2';

export interface PurchaseAssetOrderModel {
  id: number;
  offer: PurchaseAssetOfferModel;
  provider: BaseProviderData;
  status: string;
  created_at: number;
  v2: PurchaseAssetOrderModelV2;
}
