/**
 * Talala API Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AssetObjectDataV2 } from './assetObjectDataV2';
import { CreateOfferTermAssetType } from './createOfferTermAssetType';

export interface MarketplaceAssetModelV2 {
  type: CreateOfferTermAssetType;
  object: AssetObjectDataV2;
}
