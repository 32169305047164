/**
 * Talala API Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MarketplaceOfferV2 } from './marketplaceOfferV2';
import { Provider } from './provider';

export interface PurchaseAssetOrderModelV2 {
  id: string;
  offer: MarketplaceOfferV2;
  provider: Provider;
  status: string;
  created_at: number;
}
