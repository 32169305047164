/**
 * Talala API Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type GetItemsSortCriteria = 'ID' | 'PRICE' | 'RARITY';

export const GetItemsSortCriteria = {
  Id: 'ID' as GetItemsSortCriteria,
  Price: 'PRICE' as GetItemsSortCriteria,
  Rarity: 'RARITY' as GetItemsSortCriteria,
};
