/**
 * Talala API Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GlassesLimitsObjectV21 } from './glassesLimitsObjectV21';
import { FilterRangedChar } from './filterRangedChar';
import { FilterRangeDecimal } from './filterRangeDecimal';
import { FilterRangeInt } from './filterRangeInt';

export interface GlassesLimitsObject {
  chars: Array<FilterRangedChar>;
  endurance: FilterRangeDecimal;
  level: FilterRangeInt;
  rarities: Array<string>;
  sorts: Array<string>;
  v2: GlassesLimitsObjectV21;
}
