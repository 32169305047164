import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { loadConfig } from './config';

Sentry.init({
  dsn: environment.sentryClientDsn,
  environment: environment.name,
  release: environment.app_version,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay(),
  ],
  tracePropagationTargets: ['localhost'],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  beforeSendTransaction: (transaction) => {
    if (
      transaction.transaction?.includes('mc.yandex')
    ) {
      return null;
    }
    return transaction;
  },

});


if (environment.production) {
  enableProdMode();
}

async function initialize() {
  if (environment.production) {
    enableProdMode();
  }

  const appModuleContainer = await import('./app/app.module');

  platformBrowserDynamic()
    .bootstrapModule(appModuleContainer.AppModule)
    // eslint-disable-next-line no-console
    .then(() => console.log('Bootstrap success'))
    // eslint-disable-next-line no-console
    .catch(err => console.error(err));
}

loadConfig().finally(initialize);

