export const VERSION = '1.2.0';
export const TOKEN_NAME = 'Kt-GY-1tsy';
export const FIREBASE_APP_NAME = 'MarketFire';
export const YMETRIKA_CONFIG = {
  id: 90681151,
  clickmap:true,
  trackLinks:true,
  accurateTrackBounce:true,
  webvisor:true,
  ecommerce:'dataLayer',
};
