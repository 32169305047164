export * from './auth.service';
import { AuthService } from './auth.service';
export * from './exchangeRates.service';
import { ExchangeRatesService } from './exchangeRates.service';
export * from './users.service';
import { UsersService } from './users.service';
export * from './wallet.service';
import { WalletService } from './wallet.service';
export * from './walletGO.service';
import { WalletGOService } from './walletGO.service';
export * from './walletMarketplace.service';
import { WalletMarketplaceService } from './walletMarketplace.service';
export const APIS = [
  AuthService,
  ExchangeRatesService,
  UsersService,
  WalletService,
  WalletGOService,
  WalletMarketplaceService,
];
