/**
 * Talala API Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InternalProviderObjectData } from './internalProviderObjectData';

export interface InternalProviderData {
  type: InternalProviderData.TypeEnum;
  object: InternalProviderObjectData;
}
export namespace InternalProviderData {
  export type TypeEnum = 'internal';
  export const TypeEnum = {
    Internal: 'internal' as TypeEnum,
  };
}
