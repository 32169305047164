// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


import { VERSION } from '@data/config';
import { AppType } from '@api/backend';

export const environment = {
  firebase: {
    projectId: 'cheelee-1189a',
    appId: '1:114969926841:web:fa543162e0cf3deaa24f68',
    storageBucket: 'cheelee-1189a.appspot.com',
    apiKey: 'AIzaSyA3chc8Yqe_LGFSD5TmZ0pfSNkqvcpiuW4',
    authDomain: 'cheelee-1189a.firebaseapp.com',
    messagingSenderId: '114969926841',
    measurementId: 'G-46MMZTYWC8',
  },
  production: false,
  links: {
    site: 'https://crypto-v2.talala.la',
    calc: 'https://calc.talala.la',
  },
  api_base_url: 'https://chl-api-dev.talala.la/api',
  config_url: 'https://nutson-config.s3.eu-central-1.amazonaws.com/remoteHostConfig',
  name: 'development',
  app_name: 'Cheelee Market',
  app_version: VERSION,
  app_build: 'development',
  app_type: AppType.WatchToEarn,
  sentryClientDsn: 'https://784851cb51b8486cbd423197224d332b@sentry-dev.talala.la/72',
  fingerprintApiKey: 'D8ZyPA0NT68r4pUtfznh',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
