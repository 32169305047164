/**
 * Talala API Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RarityV2 } from './rarityV2';
import { GlassesAssesDataV2 } from './glassesAssesDataV2';
import { StrategyV2 } from './strategyV2';

export interface GlassesAttributesV2 {
  id: string;
  price: string;
  asset: GlassesAssesDataV2;
  endurance?: string;
  image?: string;
  rarity?: RarityV2;
  strategy?: StrategyV2;
}
