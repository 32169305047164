import { environment } from '@environments/environment';

export type Config = Partial<typeof environment>;

let appConfig: typeof environment = { ...environment };

export const getConfig = () => {
  return appConfig;
};

export const setConfig = (data: Config) => {
  appConfig = { ...appConfig, ...data };
};

export async function loadConfig(): Promise<void> {
  if (!environment.production) {
    setConfig({
      links: {
        ...environment.links,
        site: `https://${window.location.host.replace('m.', '')}`,
        calc: `https://${window.location.host.replace('m.', 'calc.')}`,
      },
    });
    return new Promise((resolve) => resolve());
  } else {
    return new Promise((resolve, reject) => {
      try {
        if (!window.location.host.includes('cheelee.us')) {
          setConfig({
            api_base_url: `https://${window.location.host.replace('m.', 'api.')}/api`,
            links: {
              ...environment.links,
              site: `https://${window.location.host.replace('m.', '')}`,
              calc: `https://${window.location.host.replace('m.', 'calc.')}`,
            },
          });
        } else {
          setConfig({
            api_base_url: 'https://api.cheeleepay.com/api',
            links: {
              ...environment.links,
              site: 'https://cheelee.us',
              calc: 'https://calc.cheelee.us',
            },
          });
        }
        return resolve();
      } catch (e) {
        return reject();
      }
    });
  }
}
