/**
 * Talala API Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExternalProviderData } from './externalProviderData';
import { AppleProviderData } from './appleProviderData';
import { InternalProviderData } from './internalProviderData';
import { ExternalProviderObjectData } from './externalProviderObjectData';

/**
 * @type ProviderItemsInner
 * @export
 */
export type ProviderItemsInner =
  | AppleProviderData
  | ExternalProviderData
  | InternalProviderData;
