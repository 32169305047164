/**
 * Talala API Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExternalProviderObjectData } from './externalProviderObjectData';

export interface ExternalProviderData {
  type: ExternalProviderData.TypeEnum;
  object: ExternalProviderObjectData;
}
export namespace ExternalProviderData {
  export type TypeEnum = 'external';
  export const TypeEnum = {
    External: 'external' as TypeEnum,
  };
}
